const middleware = {}

middleware['auth-guard'] = require('../middleware/auth-guard.ts')
middleware['auth-guard'] = middleware['auth-guard'].default || middleware['auth-guard']

middleware['candidate-guard'] = require('../middleware/candidate-guard.ts')
middleware['candidate-guard'] = middleware['candidate-guard'].default || middleware['candidate-guard']

middleware['fetch-profile'] = require('../middleware/fetch-profile.ts')
middleware['fetch-profile'] = middleware['fetch-profile'].default || middleware['fetch-profile']

middleware['i18n-guard'] = require('../middleware/i18n-guard.ts')
middleware['i18n-guard'] = middleware['i18n-guard'].default || middleware['i18n-guard']

middleware['i18n'] = require('../middleware/i18n.ts')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['post-guard'] = require('../middleware/post-guard.ts')
middleware['post-guard'] = middleware['post-guard'].default || middleware['post-guard']

middleware['protect-auth'] = require('../middleware/protect-auth.ts')
middleware['protect-auth'] = middleware['protect-auth'].default || middleware['protect-auth']

middleware['protect-candidate'] = require('../middleware/protect-candidate.ts')
middleware['protect-candidate'] = middleware['protect-candidate'].default || middleware['protect-candidate']

middleware['protect-forum-my-question'] = require('../middleware/protect-forum-my-question.ts')
middleware['protect-forum-my-question'] = middleware['protect-forum-my-question'].default || middleware['protect-forum-my-question']

middleware['protect-landing'] = require('../middleware/protect-landing.ts')
middleware['protect-landing'] = middleware['protect-landing'].default || middleware['protect-landing']

middleware['protect-lang'] = require('../middleware/protect-lang.ts')
middleware['protect-lang'] = middleware['protect-lang'].default || middleware['protect-lang']

export default middleware

import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_01f22120 from 'nuxt_plugin_plugin_01f22120' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_portalvue_8b5664e6 from 'nuxt_plugin_portalvue_8b5664e6' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_vuescrollto_577cfe7a from 'nuxt_plugin_vuescrollto_577cfe7a' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_pluginutils_0c7e4875 from 'nuxt_plugin_pluginutils_0c7e4875' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_c7e2e700 from 'nuxt_plugin_pluginrouting_c7e2e700' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7ad9aeef from 'nuxt_plugin_pluginmain_7ad9aeef' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_toast_f59de196 from 'nuxt_plugin_toast_f59de196' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_8dfe7af4 from 'nuxt_plugin_axios_8dfe7af4' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_image_b7f080fe from 'nuxt_plugin_image_b7f080fe' // Source: ./image.js (mode: 'all')
import nuxt_plugin_deviceplugin_45887411 from 'nuxt_plugin_deviceplugin_45887411' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_04aaa3bc from 'nuxt_plugin_workbox_04aaa3bc' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_ace69996 from 'nuxt_plugin_metaplugin_ace69996' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_a3974eae from 'nuxt_plugin_iconplugin_a3974eae' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_moment_75c31fd2 from 'nuxt_plugin_moment_75c31fd2' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_highlight_54dd1c6f from 'nuxt_plugin_highlight_54dd1c6f' // Source: ../plugins/highlight.ts (mode: 'all')
import nuxt_plugin_argonkit_279535f0 from 'nuxt_plugin_argonkit_279535f0' // Source: ../plugins/argon-kit (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_helpers_16d71a4f from 'nuxt_plugin_helpers_16d71a4f' // Source: ../plugins/helpers (mode: 'all')
import nuxt_plugin_vuescrolllock_4ca077a4 from 'nuxt_plugin_vuescrolllock_4ca077a4' // Source: ../plugins/vue-scroll-lock (mode: 'client')
import nuxt_plugin_vuenotification_25d70570 from 'nuxt_plugin_vuenotification_25d70570' // Source: ../plugins/vue-notification (mode: 'client')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'client')
import nuxt_plugin_vuelazyload_211b2937 from 'nuxt_plugin_vuelazyload_211b2937' // Source: ../plugins/vue-lazyload (mode: 'client')
import nuxt_plugin_vueclickoutside_68b86da3 from 'nuxt_plugin_vueclickoutside_68b86da3' // Source: ../plugins/vue-click-outside (mode: 'client')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'client')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'client')
import nuxt_plugin_vueupload_046067c4 from 'nuxt_plugin_vueupload_046067c4' // Source: ../plugins/vue-upload (mode: 'client')
import nuxt_plugin_modal_341cce12 from 'nuxt_plugin_modal_341cce12' // Source: ../plugins/modal (mode: 'client')
import nuxt_plugin_avatar_77ae2762 from 'nuxt_plugin_avatar_77ae2762' // Source: ../plugins/avatar (mode: 'client')
import nuxt_plugin_carousel_1978b254 from 'nuxt_plugin_carousel_1978b254' // Source: ../plugins/carousel (mode: 'client')
import nuxt_plugin_vuesocialsharing_dccf00ce from 'nuxt_plugin_vuesocialsharing_dccf00ce' // Source: ../plugins/vue-social-sharing (mode: 'client')
import nuxt_plugin_vuetextareaautosize_62944db4 from 'nuxt_plugin_vuetextareaautosize_62944db4' // Source: ../plugins/vue-textarea-autosize (mode: 'client')
import nuxt_plugin_vuewow_163b0ba2 from 'nuxt_plugin_vuewow_163b0ba2' // Source: ../plugins/vue-wow (mode: 'client')
import nuxt_plugin_vuetippy_73799e27 from 'nuxt_plugin_vuetippy_73799e27' // Source: ../plugins/vue-tippy (mode: 'client')
import nuxt_plugin_vuefocuslock_ef077ec6 from 'nuxt_plugin_vuefocuslock_ef077ec6' // Source: ../plugins/vue-focus-lock (mode: 'client')
import nuxt_plugin_shortuuid_361a833c from 'nuxt_plugin_shortuuid_361a833c' // Source: ../plugins/short-uuid (mode: 'all')
import nuxt_plugin_slugify_69285c3b from 'nuxt_plugin_slugify_69285c3b' // Source: ../plugins/slugify (mode: 'all')
import nuxt_plugin_vueclipboard_a146e34e from 'nuxt_plugin_vueclipboard_a146e34e' // Source: ../plugins/vue-clipboard (mode: 'client')
import nuxt_plugin_vuepagination_61e568a1 from 'nuxt_plugin_vuepagination_61e568a1' // Source: ../plugins/vue-pagination.client (mode: 'client')
import nuxt_plugin_router_3ee47c40 from 'nuxt_plugin_router_3ee47c40' // Source: ../plugins/router (mode: 'all')
import nuxt_plugin_customflag_2809f011 from 'nuxt_plugin_customflag_2809f011' // Source: ../plugins/custom-flag (mode: 'all')
import nuxt_plugin_promiserejectiontracker_935f3c64 from 'nuxt_plugin_promiserejectiontracker_935f3c64' // Source: ../plugins/promise-rejection-tracker.client (mode: 'client')
import nuxt_plugin_polyfill_2b639a93 from 'nuxt_plugin_polyfill_2b639a93' // Source: ../plugins/polyfill.client (mode: 'client')
import nuxt_plugin_handleerrorfromapi_03873a6a from 'nuxt_plugin_handleerrorfromapi_03873a6a' // Source: ../plugins/handle-error-from-api (mode: 'all')
import nuxt_plugin_vueinfiniteloading_4fedd418 from 'nuxt_plugin_vueinfiniteloading_4fedd418' // Source: ../plugins/vue-infinite-loading.client (mode: 'client')
import nuxt_plugin_vueclamp_2b92571c from 'nuxt_plugin_vueclamp_2b92571c' // Source: ../plugins/vue-clamp.client (mode: 'client')
import nuxt_plugin_vueeasylightbox_1d7783d7 from 'nuxt_plugin_vueeasylightbox_1d7783d7' // Source: ../plugins/vue-easy-lightbox.client (mode: 'client')
import nuxt_plugin_handleHover_18db42be from 'nuxt_plugin_handleHover_18db42be' // Source: ../plugins/handleHover (mode: 'all')
import nuxt_plugin_goToDetail_c5cc58ac from 'nuxt_plugin_goToDetail_c5cc58ac' // Source: ../plugins/goToDetail (mode: 'all')
import nuxt_plugin_vuelinkify_578b9b76 from 'nuxt_plugin_vuelinkify_578b9b76' // Source: ../plugins/vue-linkify (mode: 'client')
import nuxt_plugin_vueapplelogin_e254b18e from 'nuxt_plugin_vueapplelogin_e254b18e' // Source: ../plugins/vue-apple-login (mode: 'client')
import nuxt_plugin_fbsdk_6900aac2 from 'nuxt_plugin_fbsdk_6900aac2' // Source: ../plugins/fb-sdk (mode: 'client')
import nuxt_plugin_gtag_6a810249 from 'nuxt_plugin_gtag_6a810249' // Source: ../plugins/gtag (mode: 'all')
import nuxt_plugin_jsonld_593ee954 from 'nuxt_plugin_jsonld_593ee954' // Source: ../plugins/jsonld (mode: 'all')
import nuxt_plugin_auth_91c5042c from 'nuxt_plugin_auth_91c5042c' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ../plugins/auth (mode: 'all')
import nuxt_plugin_authlangredirect_09863128 from 'nuxt_plugin_authlangredirect_09863128' // Source: ../plugins/auth-lang-redirect (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s - GoEMON","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"},{"httpEquiv":"X-UA-Compatible","content":"IE=Edge"},{"hid":"description","name":"description","content":"GoEmon Japan"},{"hid":"og:title","property":"og:title","content":"GoEMON"},{"hid":"og:description","property":"og:description","content":"A support-connection platform for community in Japan"},{"hid":"og:image","property":"og:image","content":"\u002Fbanner_facebook_global.jpg"},{"hid":"og:site_name","property":"og:site_name","content":"GoEMON"},{"hid":"og:url","property":"og:url","content":"https:\u002F\u002Fgoemon-jp.com\u002F"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:site","name":"twitter:site","content":"@GoEMON"},{"hid":"twitter:title","name":"twitter:title","content":"GoEMON"},{"hid":"twitter:description","name":"twitter:description","content":"A support-connection platform for community in Japan"},{"hid":"twitter:image","name":"twitter:image","content":"\u002Fbanner_facebook_global.jpg"}],"script":[{"src":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Ftext-encoding@0.6.4\u002Flib\u002Fencoding.min.js","type":"text\u002Fjavascript"},{"src":"https:\u002F\u002Fappleid.cdn-apple.com\u002Fappleauth\u002Fstatic\u002Fjsapi\u002Fappleid\u002F1\u002Fen_US\u002Fappleid.auth.js","type":"text\u002Fjavascript"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ficon.png"},{"rel":"stylesheet","href":"https:\u002F\u002Funpkg.com\u002Fvue-select@3.1.0\u002Fdist\u002Fvue-select.css"},{"rel":"stylesheet","href":"https:\u002F\u002Fstackpath.bootstrapcdn.com\u002Ffont-awesome\u002F4.7.0\u002Fcss\u002Ffont-awesome.min.css"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_01f22120 === 'function') {
    await nuxt_plugin_plugin_01f22120(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_8b5664e6 === 'function') {
    await nuxt_plugin_portalvue_8b5664e6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_577cfe7a === 'function') {
    await nuxt_plugin_vuescrollto_577cfe7a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_0c7e4875 === 'function') {
    await nuxt_plugin_pluginutils_0c7e4875(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_c7e2e700 === 'function') {
    await nuxt_plugin_pluginrouting_c7e2e700(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7ad9aeef === 'function') {
    await nuxt_plugin_pluginmain_7ad9aeef(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_f59de196 === 'function') {
    await nuxt_plugin_toast_f59de196(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_8dfe7af4 === 'function') {
    await nuxt_plugin_axios_8dfe7af4(app.context, inject)
  }

  if (typeof nuxt_plugin_image_b7f080fe === 'function') {
    await nuxt_plugin_image_b7f080fe(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_45887411 === 'function') {
    await nuxt_plugin_deviceplugin_45887411(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_04aaa3bc === 'function') {
    await nuxt_plugin_workbox_04aaa3bc(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_ace69996 === 'function') {
    await nuxt_plugin_metaplugin_ace69996(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_a3974eae === 'function') {
    await nuxt_plugin_iconplugin_a3974eae(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_75c31fd2 === 'function') {
    await nuxt_plugin_moment_75c31fd2(app.context, inject)
  }

  if (typeof nuxt_plugin_highlight_54dd1c6f === 'function') {
    await nuxt_plugin_highlight_54dd1c6f(app.context, inject)
  }

  if (typeof nuxt_plugin_argonkit_279535f0 === 'function') {
    await nuxt_plugin_argonkit_279535f0(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_16d71a4f === 'function') {
    await nuxt_plugin_helpers_16d71a4f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrolllock_4ca077a4 === 'function') {
    await nuxt_plugin_vuescrolllock_4ca077a4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuenotification_25d70570 === 'function') {
    await nuxt_plugin_vuenotification_25d70570(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelazyload_211b2937 === 'function') {
    await nuxt_plugin_vuelazyload_211b2937(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclickoutside_68b86da3 === 'function') {
    await nuxt_plugin_vueclickoutside_68b86da3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueupload_046067c4 === 'function') {
    await nuxt_plugin_vueupload_046067c4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_modal_341cce12 === 'function') {
    await nuxt_plugin_modal_341cce12(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_avatar_77ae2762 === 'function') {
    await nuxt_plugin_avatar_77ae2762(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_carousel_1978b254 === 'function') {
    await nuxt_plugin_carousel_1978b254(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesocialsharing_dccf00ce === 'function') {
    await nuxt_plugin_vuesocialsharing_dccf00ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetextareaautosize_62944db4 === 'function') {
    await nuxt_plugin_vuetextareaautosize_62944db4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuewow_163b0ba2 === 'function') {
    await nuxt_plugin_vuewow_163b0ba2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetippy_73799e27 === 'function') {
    await nuxt_plugin_vuetippy_73799e27(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuefocuslock_ef077ec6 === 'function') {
    await nuxt_plugin_vuefocuslock_ef077ec6(app.context, inject)
  }

  if (typeof nuxt_plugin_shortuuid_361a833c === 'function') {
    await nuxt_plugin_shortuuid_361a833c(app.context, inject)
  }

  if (typeof nuxt_plugin_slugify_69285c3b === 'function') {
    await nuxt_plugin_slugify_69285c3b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclipboard_a146e34e === 'function') {
    await nuxt_plugin_vueclipboard_a146e34e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuepagination_61e568a1 === 'function') {
    await nuxt_plugin_vuepagination_61e568a1(app.context, inject)
  }

  if (typeof nuxt_plugin_router_3ee47c40 === 'function') {
    await nuxt_plugin_router_3ee47c40(app.context, inject)
  }

  if (typeof nuxt_plugin_customflag_2809f011 === 'function') {
    await nuxt_plugin_customflag_2809f011(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_promiserejectiontracker_935f3c64 === 'function') {
    await nuxt_plugin_promiserejectiontracker_935f3c64(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_polyfill_2b639a93 === 'function') {
    await nuxt_plugin_polyfill_2b639a93(app.context, inject)
  }

  if (typeof nuxt_plugin_handleerrorfromapi_03873a6a === 'function') {
    await nuxt_plugin_handleerrorfromapi_03873a6a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinfiniteloading_4fedd418 === 'function') {
    await nuxt_plugin_vueinfiniteloading_4fedd418(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclamp_2b92571c === 'function') {
    await nuxt_plugin_vueclamp_2b92571c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueeasylightbox_1d7783d7 === 'function') {
    await nuxt_plugin_vueeasylightbox_1d7783d7(app.context, inject)
  }

  if (typeof nuxt_plugin_handleHover_18db42be === 'function') {
    await nuxt_plugin_handleHover_18db42be(app.context, inject)
  }

  if (typeof nuxt_plugin_goToDetail_c5cc58ac === 'function') {
    await nuxt_plugin_goToDetail_c5cc58ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelinkify_578b9b76 === 'function') {
    await nuxt_plugin_vuelinkify_578b9b76(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueapplelogin_e254b18e === 'function') {
    await nuxt_plugin_vueapplelogin_e254b18e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fbsdk_6900aac2 === 'function') {
    await nuxt_plugin_fbsdk_6900aac2(app.context, inject)
  }

  if (typeof nuxt_plugin_gtag_6a810249 === 'function') {
    await nuxt_plugin_gtag_6a810249(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_593ee954 === 'function') {
    await nuxt_plugin_jsonld_593ee954(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_91c5042c === 'function') {
    await nuxt_plugin_auth_91c5042c(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_6a7e4e1e === 'function') {
    await nuxt_plugin_auth_6a7e4e1e(app.context, inject)
  }

  if (typeof nuxt_plugin_authlangredirect_09863128 === 'function') {
    await nuxt_plugin_authlangredirect_09863128(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
